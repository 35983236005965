import React ,{useEffect,useState}from 'react'
import { BrowserRouter, Routes, Route, Link ,NavLink} from 'react-router-dom';

export default function About() {
    const [restaurantData, setRestaurantData] = useState([]);

    useEffect(() => {
      // Function to fetch restaurant data from the API
      const fetchRestaurantData = async () => {
        try {
          const response = await fetch(
            'https://restaurantapi.speedbytes.io/api/v1/GetRestaurantWebData?restaurantID=47'
          );
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          setRestaurantData(data);
          debugger // Update the state with the API response data
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      fetchRestaurantData();
    }, []);
  return (
    <div>
        <header class="header_section" style={{color:"white",backgroundColor:"black"}}>
      <div class="container">
        <nav class="navbar navbar-expand-lg custom_nav-container ">
          <a class="navbar-brand" href="index.html">
            <span>
            {restaurantData?.name}
            </span>
          </a>

          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class=""> </span>
          </button>

          <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mx-auto">
      <li className="nav-item">
        <NavLink exact to="/" className="nav-link" activeClassName="active" >
          Home
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink to="/Menu" className="nav-link" activeClassName="active">
          Menu
        </NavLink>
      </li>
      {/* <li className="nav-item">
        <NavLink to="/Offer" className="nav-link" activeClassName="active">
          Daily Offer
        </NavLink>
      </li> */}
      <li className="nav-item">
        <NavLink to="/About" className="nav-link" activeClassName="active">
          About
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink to="/Booktable" className="nav-link" activeClassName="active">
          Book Table
        </NavLink>
      </li>
    </ul>
            {/* <div class="user_option">
              <a href="" class="user_link">
                <i class="fa fa-user" aria-hidden="true"></i>
              </a>
              <a class="cart_link" href="#">
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 456.029 456.029" >
                  <g>
                    <g>
                      <path d="M345.6,338.862c-29.184,0-53.248,23.552-53.248,53.248c0,29.184,23.552,53.248,53.248,53.248
                   c29.184,0,53.248-23.552,53.248-53.248C398.336,362.926,374.784,338.862,345.6,338.862z" />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M439.296,84.91c-1.024,0-2.56-0.512-4.096-0.512H112.64l-5.12-34.304C104.448,27.566,84.992,10.67,61.952,10.67H20.48
                   C9.216,10.67,0,19.886,0,31.15c0,11.264,9.216,20.48,20.48,20.48h41.472c2.56,0,4.608,2.048,5.12,4.608l31.744,216.064
                   c4.096,27.136,27.648,47.616,55.296,47.616h212.992c26.624,0,49.664-18.944,55.296-45.056l33.28-166.4
                   C457.728,97.71,450.56,86.958,439.296,84.91z" />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M215.04,389.55c-1.024-28.16-24.576-50.688-52.736-50.688c-29.696,1.536-52.224,26.112-51.2,55.296
                   c1.024,28.16,24.064,50.688,52.224,50.688h1.024C193.536,443.31,216.576,418.734,215.04,389.55z" />
                    </g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                </svg>
              </a>
              <form class="form-inline">
                <button class="btn  my-2 my-sm-0 nav_search-btn" type="submit">
                  <i class="fa fa-search" aria-hidden="true"></i>
                </button>
              </form>
              <a href="" class="order_online">
                Order Online
              </a>
            </div> */}
          </div>
        </nav>
      </div>
    </header>


  <section class="about_section layout_padding">
    <div class="container  ">

      <div class="row">
        <div class="col-md-6 ">
          <div class="img-box">
            <img src="images/pic10.jpg" alt=""/>
          </div>
        </div>
        <div class="col-md-6">
          <div class="detail-box">
            <div class="heading_container">
              <h2>
              {restaurantData?.name}
              </h2>
            </div>
            <p>
              There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration
              in some form, by injected humour, or randomised words which don't look even slightly believable. If you
              are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in
              the middle of text. All
            </p>
            {/* <a href="">
              Read More
            </a> */}
          </div>
        </div>
      </div>
    </div>
  </section>

  <footer className="footer_section">
    {/* <div className="container"> */}
      <div className="row">
        <div className="col-md-3 footer-col">
          <div className="footer_contact">
            <h4>
              Contact Us
            </h4>
            <div className="contact_link_box">
              <a href="">
                <i className="fa fa-map-marker" aria-hidden="true"></i>
                <span>
                {restaurantData?.street}
                      
                      {restaurantData?.postCode} {restaurantData?.city} -{" "}
                      {restaurantData?.country}                </span>
              </a>
              <a href="">
                <i className="fa fa-phone" aria-hidden="true"></i>
                <span>
                  {restaurantData?.phone}
                </span>
              </a>
              <a href="">
                <i className="fa fa-envelope" aria-hidden="true"></i>
                <span>
                {restaurantData?.email}
                </span>
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-6 footer-col">
          <div className="footer_detail">
            <a href="" className="footer-logo">
              {restaurantData.name}
            </a>
            <p>
              Necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with
            </p>
            <div className="footer_social">
              <a href="">
                <i className="fa fa-facebook" aria-hidden="true"></i>
              </a>
              <a href="">
                <i className="fa fa-twitter" aria-hidden="true"></i>
              </a>
              <a href="">
                <i className="fa fa-linkedin" aria-hidden="true"></i>
              </a>
              <a href="">
                <i className="fa fa-instagram" aria-hidden="true"></i>
              </a>
              <a href="">
                <i className="fa fa-pinterest" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-3 footer-col" style={{textAlign:"center"}}>
          <h4>
            Opening Hours
          </h4>
          {/* <p>
            <span style={{marginRight:"40px"}}>Morning</span> {" "} <span>Evening</span>
          </p>
          {restaurantData?.openingTimes?.map((time,i)=>(
          <p>
          {time.weekDay=="0"?"Sunday":time.weekDay=="1"?"Monday":time.weekDay=="2"?"Tuesday":time.weekDay=="3"?"Wednesday":time.weekDay=="4"?"Thursday":time.weekDay=="5"?"Friday":time.weekDay=="6"?"Saturday":""}  : {time.is24HrsService===true|| (time.morningTime=="-- - --"||time.morningTime=="------")?"Closed":time.morningTime}  :  {time.is24HrsService===true|| (time.eveningTime=="-- - --"||time.eveningTime=="------")?"Closed":time.eveningTime}
          </p>
          ))} */}
          <table className="table" style={{ border: "none" }}>
      <thead style={{textAlign: "justify"}}>
        <tr>
          <th style={{ color:"white", border: "none" }}>Day</th>
          <th style={{ color:"white", border: "none" }}>Morning </th>
          <th style={{ color:"white", border: "none" }}>Evening </th>
        </tr>
      </thead>
      <tbody style={{textAlign: "justify"}}>
        {restaurantData?.openingTimes?.map((time, i) => (
          <tr key={i}>
            <td style={{ border: "none", color: "white", padding: "5px 10px" }}>
              {time.weekDay === "0"
                ? "Sunday"
                : time.weekDay === "1"
                ? "Monday"
                : time.weekDay === "2"
                ? "Tuesday"
                : time.weekDay === "3"
                ? "Wednesday"
                : time.weekDay === "4"
                ? "Thursday"
                : time.weekDay === "5"
                ? "Friday"
                : time.weekDay === "6"
                ? "Saturday"
                : ""}
            </td>
            <td style={{ border: "none", color: "white", padding: "5px 10px"  }}>
              {time.is24HrsService === true ||
              time.morningTime === "-- - --" ||
              time.morningTime === "------"
                ? "Closed"
                : time.morningTime}
            </td>
            <td style={{border: "none", color: "white", padding: "5px 10px" }}>
              {time.is24HrsService === true ||
              time.eveningTime === "-- - --" ||
              time.eveningTime === "------"
                ? "Closed"
                : time.eveningTime}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
        </div>
      </div>
  </footer>
    </div>
  )
}
