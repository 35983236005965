import React,{useEffect,useState} from 'react'
import {NavLink,useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import moment from "moment";
import {toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as signalR from "@microsoft/signalr";



import {
Table,
TableBody,
TableCell,
TableContainer,
IconButton,
TableHead,
TableRow,
CircularProgress
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import PlusIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import "./index.css"

import sha512 from "js-sha512";
import PaypalPayment from './PaypalPayment';
import SignUp from './SignUp/SignUp';

var connection;
export default function Menu() {
  const history = useNavigate();
  const [isLoading, setIsLoading] = useState(true); 
  const [OrderStatuFlag, setOrderStatusFlag] = useState(""); 
    const [restaurantData, setRestaurantData] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [open3, setOpen3] = React.useState(false);

    const [email, setEmail] = useState("");
  const[Clientid,setClientid]=useState("")
  const [orderstatus, setOrderstatus] = useState("");
    const [password, setPassword] = useState("");
     const [showOrderDetail, setShowOrderDetail] = useState([]);
    const [OrderItems, setOrderItems] = useState("");
    const [OrderItemIndex, setOrderItemIndex] = useState("");
    const [foradd, setForAdd] = useState([]);
    const [getOrderDetail,setGetOrderDetail]=useState("")
  const [orderitemStatus, setOrderitemStatus] = useState("");



    const handleClickOpen = async(menu,index) => {
        debugger
        const storedToken = localStorage.getItem("token");
        if(storedToken){
            setOrderItems((prevOrderItems) => ({
                ...prevOrderItems,
                ...menu,
              }));
       let flag=false
            let detail = await GetGuestOrders(flag)
                             if(detail.length>0){
                                const firstTwoObjects = detail.slice(0, 1);
                                debugger
                                if (firstTwoObjects.some(obj => obj.orderStatus == "SUBMITTED")) {
                                    // Call the first method here
                                    debugger
                                    firstTwoObjects.map((item,i)=>{
                                        if(item.orderStatus == "SUBMITTED"){

                                            localStorage.setItem("OrderID",item.orderID)
                                            AddItems(index)
                                        }
                                    })
                              
                                    // method1();
                                  }else if(firstTwoObjects.length === 1 && firstTwoObjects.every(obj => obj.orderStatus === "CONFIRMED")){
                                    toast.error("Please contact the manager for help.", { autoClose: 5000 });
            
                                  }else{
                                SubmitOrder()
                                    
                                  }
                             }else{
                                SubmitOrder()
                             }

         
        }else{
            setOrderItems(menu)
            setOpen(true);
        }
      };

      const handleClose = () => {
        setOpen(false);
      };
      const handleClosePaypal = () => {
        setOpen3(false);
      };
      const handleCloseForOrder = () => {
        setOpen2(false);
        setForAdd([])
        setGetOrderDetail("")
      };
      const handleLogin = () => {
        // Handle login logic here
        console.log("Email:", email);
        console.log("Password:", password);
        login()
      };
      const login = async () => {
        try {
            const hashPassword = sha512(password);
            const res = await fetch("https://restaurantapi.speedbytes.io/api/v1/Login", {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ Email: email, Password: hashPassword }),
            });
    
            let loginResult = await res.json();

            if (res.status === 200) {
                localStorage.setItem("token",loginResult.token)
                localStorage.setItem("UserID",loginResult.userID)
                localStorage.setItem("GuestId",loginResult.id)
                 handleClose();
                let flag=false
                 let detail=await GetGuestOrders(flag)
debugger
                 if(detail.length>0){
                    const firstTwoObjects = detail.slice(0, 1);
                    if (firstTwoObjects.some(obj => obj.orderStatus === "SUBMITTED")) {
                        // Call the first method here
                        firstTwoObjects.map((item,i)=>{
                            if(item.orderStatus === "SUBMITTED"){
                                localStorage.setItem("OrderID",item.orderID)
                                AddItems(OrderItemIndex)
                            }
                        })
                  
                        // method1();
                      }else if(firstTwoObjects.length === 1 && firstTwoObjects.every(obj => obj.orderStatus === "CONFIRMED")){
                        toast.error("Please contact the manager for help.", { autoClose: 5000 });

                      }else{
                    SubmitOrder()
                        
                      }
                 }else{
                    SubmitOrder()
                 }
            } 
        } catch (error) {
            // toast.error("login failed", { autoClose: 5000 });
       
        }
      };
   

      const joinRoom = async () => {
        try {
          connection = new signalR.HubConnectionBuilder()
            .withUrl("https://restaurantsignals.speedbytes.io/ChatHub")
            .configureLogging(signalR.LogLevel.Information)
            .build();
    
          await connection.start();
    
          // GetActiveOrders();
    
          connection.on("OrderStatusChanged", (OrderStatusChanged) => {
            setOrderstatus(OrderStatusChanged);
    
            debugger;
            // toast.success("OrderStatusChanged Successfully", { autoClose: 5000 });
          });
    
          connection.on("OrderItemStatusChanged", (OrderItemStatusChanged) => {
            setOrderitemStatus(OrderItemStatusChanged);

            // debugger;
            // toast.success("OrderItemStatusChanged Successfully", {
            //   autoClose: 1000,
            // });
          });
        } catch (e) {
          console.log(e);
        }
      };
      const SubscribeForOrderStatus = async (resID, Orderid) => {
        try {
          debugger;
          await connection.invoke("SubscribeForOrderStatus", resID, Orderid);
        //    foradd.map((item, i) => {
        //   SubscribeForOrderItemStatus(item);
        // });
          // GetActiveOrders(resID);
          GetActiveOrderItemDetail(Orderid);
          debugger;
        } catch (e) {
          console.log(e);
        }
      };
      const SubscribeForOrderItemStatus = async (orderItem) => {
        debugger;
        try {
          await connection.invoke(
            "SubscribeForOrderItemStatus",
            orderItem.orderID || orderItem.orderId,
            orderItem.id
          );
          debugger;
        } catch (e) {
          console.log(e);
        }
      };
      const UnSubscribeForOrderStatus = async (Orderid) => {
        try {
          await connection.invoke(
            "UnSubscribeForOrderStatus",
            Orderid.restaurantID || Orderid.restaurantId,
            Orderid.id
          );
        }catch(e){

        }
      }
      const UnSubscribeForOrderItemStatus = async (orderItem) => {
        debugger;
        try {
          await connection.invoke(
            "UnsubscribeForOrderItemStatus",
            orderItem.orderID || orderItem.orderId,
            orderItem.id
          );
          debugger;
        } catch (e) {
          console.log(e);
        }
      };
      const 
      SubmitOrder = async () => {
        try {
          var token = localStorage.getItem("token");
          
          const Update = await fetch("https://restaurantapi.speedbytes.io/api/v1/SubmitOrder", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
    
            body: JSON.stringify({
              ID: "-1",
              RestaurantID: 47,
              GuestID:localStorage.getItem("GuestId"),
              DeviceID: "",
              PaymentStatus:"NOT-PAID",
              OrderItems: [],
              TableID:"TABLE N/A",
              OrderTime: moment().format(),
              OrderStartTime: moment().format(),
              WaitingTime: 30,
              OrderType: "Delivery",
              Status: "SUBMITTED",
              StatusKey: "",
              Amount: 0,
              UserID:"",
              Remarks: "",
              name: localStorage.getItem("UserID"),
            }),
          });
    
          const ab = await Update.json();
          debugger
          if (Update.status == 200 || Update.status == 204) {
            localStorage.setItem("OrderID",ab.id)
                AddItems(OrderItemIndex)
        // GetActiveOrderDetails(ab)

           
          } else {
            toast.error("SubmitFailed", { autoClose: 5000 });
          }
        } catch (e) {}
      };
      const SubmitOrderExisting = async (orderID,status) => {
        try {
          var token = localStorage.getItem("token");
          
          const Update = await fetch("https://restaurantapi.speedbytes.io/api/v1/SubmitOrder", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
    
            body: JSON.stringify({
              ID: orderID,
              RestaurantID: 47,
              GuestID: localStorage.getItem("GuestId"),
              DeviceID: "",
              PaymentStatus:"PAID-PAYPAL",
              OrderItems: [],
              TableID:"TABLE N/A",
              OrderTime: moment().format(),
              OrderStartTime: moment().format(),
              WaitingTime: 30,
              OrderType: "Delivery",
              Status: status,
              StatusKey: "",
              Amount: getOrderDetail.amount.toFixed(2),
              UserID: "",
              Remarks: "",
              name: localStorage.getItem("UserID"),
            }),
          });
    
          const ab = await Update.json();
          debugger
          if (Update.status == 200 || Update.status == 204) {
            SubscribeForOrderStatus(ab.restaurantID,orderID)
           
            showOrderDetail.map((item,i)=>{
              if (item.orderID == ab.id) {
                let arr=[...showOrderDetail]
                arr.splice(i, 1, ab);
                // setTotalamount(ab.amount.toFixed(2));
                setGetOrderDetail(ab)
                debugger
               setShowOrderDetail(arr)
              }
            })
            setOrderStatusFlag(ab.orderStatus || ab.status)

          } else {
            toast.error("SubmitFailed", { autoClose: 5000 });
          }
        } catch (e) {}
      };
      const GetPaymentInfo = async () => {
        var token = localStorage.getItem("token");
        const res = await fetch(`https://restaurantapi.speedbytes.io/api/v1/GetPaymentInfo?RestaurantId=${47}`, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        });
        const data = await res.json();
        if (data != undefined && data != null) {
          data.map((item,i)=>{
            if(item.type=="Paypal"){
                setClientid(item.clientId);
            }
          })
    
          // toast.success("PaymentInfo successfully", { autoClose: 5000 });
        } else {
          toast.error("Payment Info failed", { autoClose: 5000 });
        }
        // localStorage.setItem("currency", JSON.stringify(currency));
      };
      const AddItems = async (index) => {
        try {
            const updatedMenu = restaurantData?.menuItems[index];

          var token = localStorage.getItem("token");
          const Update = await fetch("https://restaurantapi.speedbytes.io/api/v1/QuantityPlusOne", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
            body: JSON.stringify({
                ID: -1,
            OrderID: localStorage.getItem("OrderID"),
            MenuMaster: updatedMenu.menuMaster,
            MenuItemID: updatedMenu.id,
            Code: updatedMenu.code,
            Description: updatedMenu.description,
            Quantity: 1,
            Price: updatedMenu.price,
            ItemStatus: "",
            Remarks: "",
            Photo: "",
            }),
          });
    
          var result = await Update.json();
          debugger;
          if (Update.status == 200 || Update.status == 204) {
            toast.success("Item quantity has been updated", { autoClose: 2000 });
        setOrderItemIndex("")
          } else {
            toast.error("QuantityPlusOne failed", { autoClose: 3000 });
          }
        } catch (e) {}
      };
//    useEffect(()=>{console.log(OrderItems);},[OrderItems])
const logout = async () => {
    debugger;
    var token = localStorage.getItem("token");
    var UserID = localStorage.getItem("UserID");
    try {
      const res = await fetch("https://restaurantapi.speedbytes.io/api/v1/LogOut", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          userID: UserID,
        }),
      });
      localStorage.clear();
      history("/");
      const ab = await res.json();
      if (res.status === 200||res.status===204) {
        toast.success("Logout Successfully", { autoClose: 5000 });
        localStorage.clear();
        history("/")

      } else {
        toast.error("Logout failed", { autoClose: 5000 });
        localStorage.clear();
        history("/")

      }
    } catch (error) {
        history("/")
      localStorage.clear();


    }
  };
  const GetActiveOrderDetails = async (item) => {
    debugger;
    try {
      var token = localStorage.getItem("token");
      const GetRestaurantsList = await fetch(
        `https://restaurantapi.speedbytes.io/api/v1/GetActiveOrderDetails?OrderID=${item.id||item.orderID}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
     let activeItems = await GetRestaurantsList.json();
      if (
        GetRestaurantsList.status == 204 ||
        GetRestaurantsList.status == 200
      ) {
        setForAdd(activeItems);
      }
      debugger;

      debugger;
    } catch (error) {}
  };
  const GetActiveOrderItemDetail = async (orderid) => {
    debugger;
    try {
      // setLoading(true) 
      // setIsLoading(true)
      var token = localStorage.getItem("token");
      const GetRestaurantsList = await fetch(
        `https://restaurantapi.speedbytes.io/api/v1/GetActiveOrderDetails?OrderID=${orderid}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
     let activeItems = await GetRestaurantsList.json();
      if (
        GetRestaurantsList.status == 204 ||
        GetRestaurantsList.status == 200
      ) {
        // await connection.invoke("SubscribeForOrderItemStatus", orderid, -1);
        activeItems.map((item, i) => {
          SubscribeForOrderItemStatus(item);
        });
      
        // setForAdd(activeItems);
        // setIsLoading(false)
      }
      debugger;

      debugger;
    } catch (error) {}
  };
  const QuantityPlusOneAdd = async (item, i) => {
    try {
      var token = localStorage.getItem("token");
      const Update = await fetch("https://restaurantapi.speedbytes.io/api/v1/QuantityPlusOne", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          ID: item.id,
          OrderID: item.orderId || item.orderID,
          MenuMaster: item.menuMaster,
          MenuItemID: item.menuItemId || item.menuItemID,
          Code: item.code,
          Description: item.description,
          Quantity: 1,
          Price: item.price,
          ItemStatus: "",
          Remarks: "",
          Photo: "",
        }),
      });

      const ab = await Update.json();
      debugger;
      if (Update.status == 200 || Update.status == 204) {
        foradd.map((addItem, index) => {
          if (addItem.id == ab.id) {
            let newArr = [...foradd];
            newArr[index].quantity = ab.quantity;
            newArr[index].orderId = ab.orderId;
            newArr[index].price = ab.price;
            newArr[index].itemStatus = ab.itemStatus;
            newArr[index].remarks = ab.remarks;

            setForAdd(newArr);
            // showOrderDetail[0].amount+=ab.price
            // getOrderDetail.amount+=ab.price
            GetGuestOrders(false)

            debugger;
          }
        });

        toast.success("Item quantity has been updated", { autoClose: 3000 });

        debugger;
        return ab;
      } else {
        toast.error("QuantityPlusOne failed", { autoClose: 3000 });
        return ab;
      }
    } catch (e) {}
  };

  const QuantityMinusOne = async (add, i) => {
    try {
      var token = localStorage.getItem("token");
      debugger;

      const Update = await fetch("https://restaurantapi.speedbytes.io/api/v1/QuantityMinusOne", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          ID: add.id,
          OrderID: add.orderId || add.orderID,
          MenuMaster: add.menuMaster,
          MenuItemID: add.menuItemId || add.menuItemID,
          Code: add.code,
          Description: add.description,
          Quantity: 1,
          Price: add.price,
          ItemStatus: "",
          Remarks: "",
          Photo: "",
        }),
      });

      const ab = await Update.json();

      debugger;
      if (Update.status == 200 || Update.status == 204) {
        foradd.map(async (addItem, index) => {
          if (ab.quantity == 0) {
            let newArr = [...foradd];
            newArr.splice(index, 1);
            setForAdd(newArr);
            GetGuestOrders(false)

          } else {
            if (addItem.id == ab.id) {
              let newArr = [...foradd];
              newArr[index].quantity = ab.quantity;
              newArr[index].orderId = ab.orderId;
              newArr[index].price = ab.price;
              newArr[index].itemStatus = ab.itemStatus;
              newArr[index].remarks = ab.remarks;

              setForAdd(newArr);
            //  if(getOrderDetail.amount>0){
                // getOrderDetail.amount-=ab.price
                // showOrderDetail[0].amount-=ab.price

              // }
            GetGuestOrders(false)

            }
          }
        });

         toast.success("Item quantity has been updated", { autoClose: 3000 });

      
      } else {
        toast.error("QuantityMinusOne failed", { autoClose: 5000 });
      }
    } catch (e) {}
  };
  const QuantityToZero = async (add, i) => {
    try {
      var token = localStorage.getItem("token");
      const Update = await fetch("https://restaurantapi.speedbytes.io/api/v1/QuantityToZero", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          ID: add.id,
          OrderID: add.orderId || add.orderID,
          MenuMaster: add.menuMaster,
          MenuItemID: add.menuItemId || add.menuItemID,
          Code: add.code,
          Description: add.description,
          Quantity: 1,
          Price: add.price,
          ItemStatus: "",
          Remarks: "",
          Photo: "",
        }),
      });

      const ab = await Update.json();
      debugger;
      if (Update.status == 200 || Update.status == 204) {
        // UnSubscribeForOrderItemStatus(ab);
        foradd.map(async (addItem, index) => {
          if (ab.id == addItem.id) {
            let newArr = [...foradd];
            newArr.splice(index, 1);
            setForAdd(newArr);
            // getOrderDetail.amount=0
            // showOrderDetail[0].amount=0
            GetGuestOrders(false)
            debugger;
          }
        });
    
      } else {
        toast.error("QuantityToZero failed", { autoClose: 5000 });
      }
    } catch (e) {}
  };

  const GetGuestOrders = async (flag) => {
    try {
      var token = localStorage.getItem("token");
      const guestid=localStorage.getItem("GuestId")
      const res = await fetch(
        `https://restaurantapi.speedbytes.io/api/v1/GetGuestOrders?GuestID=${guestid}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const data = await res.json();
      if (res.status === 200 || res.status === 204) {
        debugger

        if (data.length > 0) {
          if(flag==true){
            data.map((item,i)=>{
              if(item.orderStatus==="CONFIRMED"){
                SubscribeForOrderStatus(item.restaurantID,item.orderID)
  
              }
            })
          }
          setShowOrderDetail(data);
          setGetOrderDetail(data[0])
        setIsLoading(false)
       return data
        } else {
        //   toast.success("There are no order under your account in the system", {
        //     autoClose: 5000,
        //   });
        setIsLoading(false)

          return []
        }
      }
      debugger;
    } catch (error) {}
  };

    useEffect(() => {
      // Function to fetch restaurant data from the API
      const fetchRestaurantData = async () => {
        try {
          const response = await fetch(
            'https://restaurantapi.speedbytes.io/api/v1/GetRestaurantWebData?restaurantID=47'
          );
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          setRestaurantData(data);
          debugger // Update the state with the API response data
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      fetchRestaurantData();
      if(localStorage.getItem("token")){
        GetGuestOrders(false)

      }
    }, []);
 
    useEffect(() => {
      joinRoom();
    }, []);
  
    useEffect(() => {
      if (orderstatus != "") {
        debugger
        if (orderstatus != null && orderstatus != undefined) {
          if (showOrderDetail != null) {
            showOrderDetail.map((item,i)=>{
            if (item.orderID || item.id == orderstatus.id) {
              let arr=[...showOrderDetail]
              arr.splice(i, 1, orderstatus);
              // setTotalamount(orderstatus.amount.toFixed(2));
              setGetOrderDetail(orderstatus)
              debugger
             setShowOrderDetail(arr)
  
              debugger;
              if (
                orderstatus.status == "CANCELLED"||orderstatus.status == "COMPLETED") {
              
                let OrderDetail = [
                    ... showOrderDetail
                ]
                OrderDetail.splice(i, 1);
                setShowOrderDetail(OrderDetail)
                UnSubscribeForOrderStatus(orderstatus);
        
                foradd&&foradd.map((item) => {
                  UnSubscribeForOrderItemStatus(item);
                });
                setForAdd([])
                getOrderDetail.amount=0
                debugger
                setOpen2(false)
if(orderstatus.status == "CANCELLED"){
  toast.error("Your order has been cancelled", { autoClose: 3000 });
}else{
  toast.success("Your order has been completed", { autoClose: 3000 });

}
              }
            }
            setOrderstatus("")

          })
          }
        }
      }
    }, [orderstatus]);
    useEffect(() => {
      if (orderitemStatus != "") {
        debugger
        foradd.map(async (item, index) => {
          if (item.id == orderitemStatus.id) {
            let arr = [...foradd];
            arr.splice(index, 1, orderitemStatus);
            setForAdd(arr);
            setOrderitemStatus("");
            debugger;
          }
        });
  
        // orderitem.map(async (item, index) => {
        //   if (item.id == orderitemStatus.id) {
        //     let arr = [...orderitem];
        //     arr.splice(index, 1, orderitemStatus);
        //     setOrderitem(arr);
        //     setOrderitemStatus("");
        //     debugger;
        //   }
        // });
      }
      debugger;
    }, [orderitemStatus]);

    useEffect(()=>{
    },[showOrderDetail])
    
  return (
    <>
    <div>
       
        {/* <div class="hero_area">
    <div class="bg-box">
      <img src="images/hero-bg.jpg" alt=""/> 
    </div>
 
  </div> */}
  <header class="header_section" style={{color:"white",backgroundColor:"black"}}>
      <div class="container">
        <nav class="navbar navbar-expand-lg custom_nav-container ">
        <NavLink exact to="/" className="nav-link" activeClassName="active">
          <a className="navbar-brand">
            <span>
              {restaurantData?.name}
            </span>
          </a>
        </NavLink>

          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class=""> </span>
          </button>

          <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mx-auto">
      <li className="nav-item">
        <NavLink exact to="/" className="nav-link" activeClassName="active" >
          Home
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink to="/Menu" className="nav-link" activeClassName="active">
          Menu
        </NavLink>
      </li>
      {/* <li className="nav-item">
        <NavLink to="/Offer" className="nav-link" activeClassName="active">
          Daily Offer
        </NavLink>
      </li> */}
       {/* <li className="nav-item">
        <NavLink to="/About" className="nav-link" activeClassName="active">
          About
        </NavLink>
      </li> */}
      <li className="nav-item">
        <NavLink to="/Booktable" className="nav-link" activeClassName="active">
          Book Table
        </NavLink>
      </li> 
    </ul>
            <div class="user_option">
            {localStorage.getItem("token")!==undefined&&localStorage.getItem("token")!==null?(<></>):(<> <a href="#" class="user_link" onClick={()=>{setOpen(true);
              }}>
                <i class="fa fa-user" aria-hidden="true" ></i>
              </a></>)}
             
              <a class="cart_link" href="#" onClick={()=>{setOpen2(true);
            //   GetActiveOrderDetails()
            
             
                  
                  setOrderStatusFlag(showOrderDetail[0]?.orderStatus ||showOrderDetail[0]?.status)
        

            let flag=true
                 GetGuestOrders(flag)
                 setTimeout(()=>{
                  GetActiveOrderDetails(showOrderDetail[0])
                 },2000)
              }}>
                 <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xlinkHref="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 456.029 456.029"
        width="100"
        height="100"
      >
        <g>
          <g>
            <path
              d="M345.6,338.862c-29.184,0-53.248,23.552-53.248,53.248c0,29.184,23.552,53.248,53.248,53.248 c29.184,0,53.248-23.552,53.248-53.248C398.336,362.926,374.784,338.862,345.6,338.862z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M439.296,84.91c-1.024,0-2.56-0.512-4.096-0.512H112.64l-5.12-34.304C104.448,27.566,84.992,10.67,61.952,10.67H20.48 C9.216,10.67,0,19.886,0,31.15c0,11.264,9.216,20.48,20.48,20.48h41.472c2.56,0,4.608,2.048,5.12,4.608l31.744,216.064 c4.096,27.136,27.648,47.616,55.296,47.616h212.992c26.624,0,49.664-18.944,55.296-45.056l33.28-166.4 C457.728,97.71,450.56,86.958,439.296,84.91z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M215.04,389.55c-1.024-28.16-24.576-50.688-52.736-50.688c-29.696,1.536-52.224,26.112-51.2,55.296 c1.024,28.16,24.064,50.688,52.224,50.688h1.024C193.536,443.31,216.576,418.734,215.04,389.55z"
            />
          </g>
        </g>
      </svg>

      {/* Red Dot SVG */}
      {showOrderDetail.length > 0 && (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="10" cy="10" r="5" fill="green" />
        </svg>
      )}

              </a>
              <form class="form-inline">
                {/* <button class="btn  my-2 my-sm-0 nav_search-btn" type="submit">
                  <i class="fa fa-search" aria-hidden="true"></i>
                </button> */}
              </form>
              {localStorage.getItem("token")!==undefined&&localStorage.getItem("token")!==null?(<>  <a className="order_online" style={{color:"white"}} onClick={()=>logout()}>
                Logout
              </a></>):(<><a style={{color:"white"}} className="order_online"   data-toggle="modal"
              data-target=".SignUp">
             Register
              </a></>)}
            </div>
          </div>
        </nav>
      </div>
    </header>
<div className='row' style={{justifyContent:"center"}}>
<div className='col-8' style={{marginTop:"90px"}}>
  <section className="food_section">
    <div className="container">
      <div className="heading_container heading_center">
        <h2>
          Daily Special Offer
        </h2>
      </div>

      <div class="row">
        <div className="col-12">
        <div id="accordion1">
         
            <div className="col-12" key={4444}>
              <div className="card">
                <div
                  className="card-header"
                  id={`heading${4444}`}
                  style={{ backgroundColor: "#ffbe33", textAlign: "center" }}
                >
                  <h5 className="mb-0">
                    <button
                      className="btn btn-link"
                      data-toggle="collapse"
                      data-target={`#collapse${4444}`}
                      aria-expanded="true"
                      aria-controls={`collapse${4444}`}
                    >
                      <span style={{ display: "block", width: "100%", color: "white" }}>
                        Daily Offer
                      </span>
                    </button>
                  </h5>
                </div>

                <div className="collapse show" id={`collapse${4444}`} aria-labelledby={`heading${4444}`} data-parent="#accordion1">
                
                    <div className="card-body">
                <div className="row">
                  {restaurantData?.dailyMenuItems?.map((menu,index) => (
                    <React.Fragment key={menu.id}>
                     
                        <div className="col-sm-4 col-lg-4 all pizza">
                          <div className="box">
                            <div>
                              <div className="img-box">
                                <img
                                  src={`https://images.speedbytes.io/RestaurantImages/${menu.photo}`}
                                  alt={menu.name}
                                />
                              </div>
                              <div className="detail-box">
                                <p>{menu.description}</p>
                                <div className="options">
                                  <h6>{menu.detailInfo == null ? "" : menu.detailInfo}</h6>
                                  {/* <h6></h6> */}
                                </div>
                                <div className="options">
                   <h6 > 
                   Price : {menu.price.toFixed(2)}
                    </h6> 
                    <a style={{cursor:"pointer"}}    onClick={() => {handleClickOpen(menu,index);
                    setOrderItemIndex(index)
                    }}>
                      <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  xlinkHref="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 456.029 456.029"  >
                        <g>
                          <g>
                            <path d="M345.6,338.862c-29.184,0-53.248,23.552-53.248,53.248c0,29.184,23.552,53.248,53.248,53.248
                         c29.184,0,53.248-23.552,53.248-53.248C398.336,362.926,374.784,338.862,345.6,338.862z" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <path d="M439.296,84.91c-1.024,0-2.56-0.512-4.096-0.512H112.64l-5.12-34.304C104.448,27.566,84.992,10.67,61.952,10.67H20.48
                         C9.216,10.67,0,19.886,0,31.15c0,11.264,9.216,20.48,20.48,20.48h41.472c2.56,0,4.608,2.048,5.12,4.608l31.744,216.064
                         c4.096,27.136,27.648,47.616,55.296,47.616h212.992c26.624,0,49.664-18.944,55.296-45.056l33.28-166.4
                         C457.728,97.71,450.56,86.958,439.296,84.91z" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <path d="M215.04,389.55c-1.024-28.16-24.576-50.688-52.736-50.688c-29.696,1.536-52.224,26.112-51.2,55.296
                         c1.024,28.16,24.064,50.688,52.224,50.688h1.024C193.536,443.31,216.576,418.734,215.04,389.55z" />
                          </g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                      </svg>
                    </a>
                  </div> 
                              </div>
                            </div>
                          </div>
                        </div>
            
                    </React.Fragment>
                  ))}
                </div>
              </div>
                </div>
              </div>
            </div>
   
        </div>
      </div>
         
        </div>
    </div>
  </section>
  </div>

  <div className='col-8 mt-4'>
  <section class="food_section">
    <div class="container">
      <div class="heading_container heading_center">
        <h2>
          Our Main Menu
        </h2>
      </div>

      {/* <ul class="filters_menu">
        <li class="active" data-filter="*">All</li>
        <li data-filter=".burger">Burger</li>
        <li data-filter=".pizza">Pizza</li>
        <li data-filter=".pasta">Pasta</li>
        <li data-filter=".fries">Fries</li>
      </ul> */}

      {/* <div class="filters-content"> */}
        <div class="row">
        <div className="col-12">
        <div id="accordion3">
          {restaurantData?.categories?.map((cat) => (
            <div className="col-12" key={cat.id}>
              <div className="card">
                <div
                  className="card-header"
                  id={`heading${cat.id}`}
                  style={{ backgroundColor: "#ffbe33", textAlign: "center" }}
                >
                  <h5 className="mb-0">
                    <button
                      className="btn btn-link"
                      data-toggle="collapse"
                      data-target={`#collapse${cat.id}`}
                      aria-expanded="false"
                      aria-controls={`collapse${cat.id}`}
                    >
                      <span style={{ display: "block", width: "100%", color: "white" }}>
                        {cat.description}
                      </span>
                    </button>
                  </h5>
                </div>

                <div
                  id={`collapse${cat.id}`}
                  className="collapse"
                  aria-labelledby={`heading${cat.id}`}
                  data-parent="#accordion3"
                >
                    <div className="card-body">
                <div className="row">
                  {restaurantData?.menuItems?.map((menu,index) => (
                    <React.Fragment key={menu.id}>
                      {menu.categoryID === cat.id && (
                        <div className="col-sm-4 col-lg-4 all pizza">
                          <div className="box">
                            <div>
                              <div className="img-box">
                                <img
                                  src={`https://images.speedbytes.io/RestaurantImages/${menu.photo}`}
                                  alt={menu.name}
                                />
                              </div>
                              <div className="detail-box">
                                <p>{menu.description}</p>
                                <div className="options">
                                  <h6>{menu.detailInfo == null ? "" : menu.detailInfo}</h6>
                                  {/* <h6></h6> */}
                                </div>
                                <div className="options">
                   <h6 > 
                   Price : {menu.price.toFixed(2)}
                    </h6> 
                    <a style={{cursor:"pointer"}}    onClick={() => {handleClickOpen(menu,index);
                    setOrderItemIndex(index)
                    }}>
                      <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  xlinkHref="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 456.029 456.029"  >
                        <g>
                          <g>
                            <path d="M345.6,338.862c-29.184,0-53.248,23.552-53.248,53.248c0,29.184,23.552,53.248,53.248,53.248
                         c29.184,0,53.248-23.552,53.248-53.248C398.336,362.926,374.784,338.862,345.6,338.862z" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <path d="M439.296,84.91c-1.024,0-2.56-0.512-4.096-0.512H112.64l-5.12-34.304C104.448,27.566,84.992,10.67,61.952,10.67H20.48
                         C9.216,10.67,0,19.886,0,31.15c0,11.264,9.216,20.48,20.48,20.48h41.472c2.56,0,4.608,2.048,5.12,4.608l31.744,216.064
                         c4.096,27.136,27.648,47.616,55.296,47.616h212.992c26.624,0,49.664-18.944,55.296-45.056l33.28-166.4
                         C457.728,97.71,450.56,86.958,439.296,84.91z" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <path d="M215.04,389.55c-1.024-28.16-24.576-50.688-52.736-50.688c-29.696,1.536-52.224,26.112-51.2,55.296
                         c1.024,28.16,24.064,50.688,52.224,50.688h1.024C193.536,443.31,216.576,418.734,215.04,389.55z" />
                          </g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                      </svg>
                    </a>
                  </div> 
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </React.Fragment>
                  ))}
                </div>
              </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
         
        </div>
     
      <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="custom-dialog-title"
        PaperProps={{
        style: {
          minWidth: "700px", // Set the desired width here
          maxWidth: "500px", // Optionally, set the maximum width
        },
      }}
      >
      <DialogTitle id="custom-dialog-title" style={{backgroundColor:"rgb(255, 190, 51)",color:"white",marginBottom:"20px"}}>Login</DialogTitle>
      <DialogContent style={{paddingTop: "10px"}}>
       
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <TextField
            required
            label="Email"
            type="email"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            required
            label="Password"
            type="password"
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
      </DialogContent>
      <DialogActions style={{ display: "flex", justifyContent: "space-between" }}>
        <Button variant="contained"  style={{backgroundColor:"rgb(255, 190, 51)",color:"white"}} onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" style={{backgroundColor:"rgb(255, 190, 51)",color:"white"}} onClick={()=>{
   
            handleLogin()

        
          }} disabled={!email || !password}>
          Login
        </Button>
      </DialogActions>
    </Dialog>
      </div>
      {/* **************************Paypal */}
      <div>
      <Dialog open={open3} onClose={handleClosePaypal} aria-labelledby="custom-dialog-title"
        PaperProps={{
        style: {
          minWidth: "700px", // Set the desired width here
          maxWidth: "500px", // Optionally, set the maximum width
        },
      }}
      >
      <DialogTitle id="custom-dialog-title">Paypal Payment</DialogTitle>
      <DialogContent>
       
        <PaypalPayment
                       handleclose={handleClosePaypal}
                           updateOrderdata={getOrderDetail}
                           submit={SubmitOrderExisting}
                           clientid={Clientid}
        />
      </DialogContent>
      <DialogActions style={{ display: "flex", justifyContent: "space-between" }}>
        <Button variant="contained" color="primary" onClick={handleClosePaypal}>
          Close
        </Button>
        
      </DialogActions>
    </Dialog>
      </div>


      <div>
      <Dialog
        open={open2}
        onClose={handleCloseForOrder}
        aria-labelledby="custom-dialog-title"
        PaperProps={{
          style: {
            minWidth: "900px", // Set the desired width here
            maxWidth: "500px", // Optionally, set the maximum width
          },
        }}
      >
        <DialogTitle id="custom-dialog-title">Order Detail</DialogTitle>
        <DialogContent>
        {isLoading ? ( // Step 3: Use conditional rendering for loader
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "200px" }}>
              <CircularProgress />
            </div>
          ) : (
          <TableContainer>

          <Table>
              <TableHead  sx={{ backgroundColor: "#f0f0f0",color:"white",fontWeight:"bold"}}>
                <TableRow>
                  <TableCell align='center' style={{maxWidth: "51px"}}>Order ID</TableCell>
                  <TableCell> Order Status</TableCell>
                  <TableCell> Price</TableCell>
                  <TableCell> Payment</TableCell>
                
             
                   {/* Column for tick icon */}
                </TableRow>
              </TableHead>
              <TableBody>
                {showOrderDetail&&showOrderDetail.map((item,i) => (
                  <TableRow className='tableData' key={item.orderID} style={{cursor:"pointer"}} 
                  onClick={()=>{
                    setGetOrderDetail(item)
                  // GetActiveOrderDetails(item)
                  // setOrderStatusFlag(item.orderStatus || item.status)
                  }}
                  >
                    <TableCell align='center'>{item.orderID || item.id}</TableCell>
                    <TableCell>{item.orderStatus || item.status}</TableCell>
                    <TableCell>{item.amount.toFixed(2)}</TableCell>
                    <TableCell><Button variant="contained" color="primary" disabled={item.orderStatus==="CONFIRMED" || item.status==="CONFIRMED"?true:false} onClick={()=>{
                      setOpen3(true)
                 GetPaymentInfo()

                    }}>
            Payment
          </Button></TableCell>
                 
                 
                   
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <DialogTitle id="custom-dialog-title" style={{marginLeft:"-24px"}}>Order Items</DialogTitle>
            <Table>
              <TableHead  sx={{ backgroundColor: "#f0f0f0",color:"white",fontWeight:"bold"}}>
                <TableRow>
                  <TableCell style={{ textAlign: 'right' }}>Code</TableCell>
                  <TableCell > Description</TableCell>
                  <TableCell> Price</TableCell>
                  <TableCell  style={{ textAlign: 'left' }}> Quantity</TableCell>
                  <TableCell> Item Status</TableCell>
                  <TableCell> 

                  {/* <CheckIcon/> */}
                  </TableCell> 
                  <TableCell>

                  {/* <RemoveIcon/> */}
                  </TableCell> {/* Column for minus icon */}
                  {/* <TableCell><DeleteIcon/></TableCell> Column for minus icon */}

                  {/* Column for cross icon */}
                  <TableCell>

                  {/* <ClearIcon/> */}
                  </TableCell>
                   {/* Column for tick icon */}
                </TableRow>
              </TableHead>
              <TableBody>
                {foradd&&foradd?.map((item,i) => (
                  <TableRow key={item.code} style={{
                                              backgroundColor:
                                                item.itemStatus == "READY"
                                                  ? "#F0E5CC"
                                                  : item.itemStatus == "WORKING"
                                                  ? "#E7BDFF"
                                                  : item.itemStatus ==
                                                    "CONFIRMED"
                                                  ? "#E1EEF4"
                                                  : item.itemStatus == "REDO"
                                                  ? "#DFFFDE"
                                                  : item.itemStatus == "PAUSED"
                                                  ? "#F7CDCD"
                                                  : item.itemStatus == "DELAYED"
                                                  ? "#DBDBDB"
                                                  : "#fff",
                                              color:
                                                item.itemStatus == "READY"
                                                  ? "#7F4614"
                                                  : item.itemStatus == "WORKING"
                                                  ? "#4D1A75"
                                                  : item.itemStatus ==
                                                    "CONFIRMED"
                                                  ? "#00557F"
                                                  : item.itemStatus == "REDO"
                                                  ? "#275420"
                                                  : item.itemStatus == "PAUSED"
                                                  ? "#80141C"
                                                  : item.itemStatus == "DELAYED"
                                                  ? "#7D7D7D"
                                                  : "black",
                                            }}>
                    <TableCell style={{ textAlign: 'right' }}>{item.code}</TableCell>
                    <TableCell >{item.description}</TableCell>
                    <TableCell>{item.price.toFixed(2)}</TableCell>
                    <TableCell  style={{ textAlign: 'left',maxWidth:"5px" }}>{item.quantity}</TableCell>
                    <TableCell>{item.itemStatus}</TableCell>
                 
                    <TableCell style={{maxWidth:"5px"}}>
                      <IconButton  disabled={OrderStatuFlag=="CONFIRMED"?true:false} onClick={()=>{
                    QuantityPlusOneAdd(item,i)
                      }}>
                        <PlusIcon/>
                      </IconButton>
                    </TableCell>
                    <TableCell  style={{maxWidth:"5px"}}>
                      <IconButton disabled={OrderStatuFlag=="CONFIRMED"?true:false} onClick={()=>{
                    QuantityMinusOne(item,i)
                      }}>
                        <RemoveIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <IconButton disabled={OrderStatuFlag=="CONFIRMED"?true:false}>
                        <DeleteIcon onClick={()=>{
                    QuantityToZero(item,i)
                      }}/>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          )}
        </DialogContent>
        <DialogActions style={{ display: "flex", justifyContent: "space-between",fontWeight:"bold" }}>
          Total Amount : {getOrderDetail.businessCurrency} {getOrderDetail?.amount?.toFixed(2)}
          <div style={{float:"right"}}>
          <Button variant="contained" color="primary" onClick={handleCloseForOrder} style={{marginRight:"10px"}}>
            Close
          </Button>
          {/* <Button variant="contained" color="primary" onClick={handleLogin}>
            Done
          </Button> */}
          </div>
        </DialogActions>
      </Dialog>
    </div>
    </div>

  </section></div>
</div>


 
  <section className="client_section layout_padding-bottom">
    <div className="container">
      <div className="heading_container heading_center psudo_white_primary mb_45">
        {/* <h2>
          What Says Our Customers
        </h2> */}
      </div>
      {/* <div className="carousel-wrap row ">
        <div className="owl-carousel client_owl-carousel">
          <div className="item">
            <div className="box">
              <div className="detail-box">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam
                </p>
                <h6>
                  Moana Michell
                </h6>
                <p>
                  magna aliqua
                </p>
              </div>
              <div className="img-box">
                <img src="images/client1.jpg" alt="" className="box-img"/>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="box">
              <div className="detail-box">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam
                </p>
                <h6>
                  Mike Hamell
                </h6>
                <p>
                  magna aliqua
                </p>
              </div>
              <div className="img-box">
                <img src="images/client2.jpg" alt="" className="box-img"/>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  </section>


  <footer className="footer_section">
    {/* <div className="container"> */}
      <div className="row">
        <div className="col-md-3 footer-col">
          <div className="footer_contact">
            <h4>
              Contact Us
            </h4>
            <div className="contact_link_box">
              <a href="">
                <i className="fa fa-map-marker" aria-hidden="true"></i>
                <span>
                {restaurantData?.street}{" "}
                      
                      {restaurantData?.postCode} {restaurantData?.city} -{" "}
                      {restaurantData?.country}
                </span>
              </a>
              <a href="">
                <i className="fa fa-phone" aria-hidden="true"></i>
                <span>
                  {restaurantData?.phone}
                </span>
              </a>
              <a href="">
                <i className="fa fa-envelope" aria-hidden="true"></i>
                <span>
                {restaurantData?.email}
                </span>
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-6 footer-col">
          <div className="footer_detail">
            <a href="/" className="footer-logo">
              {restaurantData.name}
            </a>
         
            <p>
              Necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with
            </p>
            <div className="footer_social">
              <a href={restaurantData?.socialLinks?.[1]?.mediaLink} target="_blank">
                <i className="fa fa-facebook" aria-hidden="true"></i>
              </a>
              <a href={restaurantData?.socialLinks?.[2]?.mediaLink} target="_blank">
                <i className="fa fa-twitter" aria-hidden="true"></i>
              </a>
              <a href={restaurantData?.socialLinks?.[3]?.mediaLink} target="_blank">
                <i className="fa fa-instagram" aria-hidden="true"></i>
              </a>
              <a href={restaurantData?.socialLinks?.[4]?.mediaLink} target="_blank">
                <i className="fa fa-google" aria-hidden="true"></i>
              </a>
              {/* <a href="">
                <i className="fa fa-linkedin" aria-hidden="true"></i>
              </a>
              <a href="">
                <i className="fa fa-instagram" aria-hidden="true"></i>
              </a>
              <a href="">
                <i className="fa fa-pinterest" aria-hidden="true"></i>
              </a> */}
            </div>
          </div>
        </div>
        <div className="" style={{marginLeft:"65px"}}>
          <h4 style={{textAlign:"center"}}>
            Opening Hours
          </h4>
          {/* <p>
            <span style={{marginRight:"40px"}}>Morning</span> {" "} <span>Evening</span>
          </p>
          {restaurantData?.openingTimes?.map((time,i)=>(
          <p>
          {time.weekDay=="0"?"Sunday":time.weekDay=="1"?"Monday":time.weekDay=="2"?"Tuesday":time.weekDay=="3"?"Wednesday":time.weekDay=="4"?"Thursday":time.weekDay=="5"?"Friday":time.weekDay=="6"?"Saturday":""}  : {time.is24HrsService===true|| (time.morningTime=="-- - --"||time.morningTime=="------")?"Closed":time.morningTime}  :  {time.is24HrsService===true|| (time.eveningTime=="-- - --"||time.eveningTime=="------")?"Closed":time.eveningTime}
          </p>
          ))} */}
        <table className="table" style={{ border: "none" }}>
  <thead style={{ textAlign: "justify" }}>
    <tr>
      <th style={{ color: "white", border: "none" }}>Day</th>
      <th style={{ color: "white", border: "none" }}>Morning</th>
      <th style={{ color: "white", border: "none" }}>Evening</th>
    </tr>
  </thead>
  <tbody style={{ textAlign: "justify" }}>
    {restaurantData?.openingTimes?.map((time, i) => (
      <tr key={i}>
        <td style={{ border: "none", color: "white", padding: "5px 10px", margin: "0" }}>
          {time.weekDay === "0"
            ? "Sunday"
            : time.weekDay === "1"
            ? "Monday"
            : time.weekDay === "2"
            ? "Tuesday"
            : time.weekDay === "3"
            ? "Wednesday"
            : time.weekDay === "4"
            ? "Thursday"
            : time.weekDay === "5"
            ? "Friday"
            : time.weekDay === "6"
            ? "Saturday"
            : ""}
        </td>
        <td style={{ border: "none", color: "white", padding: "5px 10px", margin: "0" }}>
          {time.is24HrsService === true ||
          time.morningTime === "-- - --" ||
          time.morningTime === "------"
            ? "Closed"
            : time.morningTime}
        </td>
        <td style={{ border: "none", color: "white", padding: "5px 10px", margin: "0" }}>
          {time.is24HrsService === true ||
          time.eveningTime === "-- - --" ||
          time.eveningTime === "------"
            ? "Closed"
            : time.eveningTime}
        </td>
      </tr>
    ))}
  </tbody>
</table>
        </div>
      </div>
      {/* <div className="footer-info">
        <p>
          &copy; <span id="displayYear"></span> All Rights Reserved By
          <a href="https://html.design/">Free Html Templates</a><br/><br/>
          &copy; <span id="displayYear"></span> Distributed By
          <a href="https://themewagon.com/" target="_blank">ThemeWagon</a>
        </p>
      </div> */}
    {/* </div> */}
  </footer>
  <SignUp/>

    </div>


    </>
  )
}
