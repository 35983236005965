import React, { useEffect, useState } from 'react';
import {
    PayPalScriptProvider,
    PayPalButtons,
    usePayPalScriptReducer
} from "@paypal/react-paypal-js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CircularProgress } from '@mui/material';

export default function PaypalPayment({ handleclose, updateOrderdata, submit, clientid }) {
    const [currency, setCurrency] = useState(updateOrderdata.businessCurrency);
    const [amount, setAmount] = useState(updateOrderdata.amount.toFixed(2));

    useEffect(() => {
        setCurrency(updateOrderdata.businessCurrency);
        setAmount(updateOrderdata.amount.toFixed(2));
    }, [updateOrderdata.businessCurrency, updateOrderdata.amount]);

    return (
        <div>
            {clientid && (
                <PayPalScriptProvider options={{ clientId: clientid, components: "buttons", currency }}>
                    <PayPalButtons
                        createOrder={(data, actions) => {
                            return actions.order.create({
                                purchase_units: [
                                    {
                                        amount: {
                                            currency_code: currency,
                                            value: amount,
                                        },
                                    },
                                ],
                            });
                        }}
                        onApprove={async (details, data) => {
                            try {
                                var token = localStorage.getItem("token");
                                const Update = await fetch("https://restaurantapi.speedbytes.io/api/v1/payments/create-payment-paypal", {
                                    method: "POST",
                                    headers: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json",
                                        Authorization: "Bearer " + token,
                                    },
                                    body: JSON.stringify({
                                        stripeToken: "",
                                        RestaurantID: updateOrderdata.restaurantID,
                                        OrderID: updateOrderdata.orderID,
                                        Currency: currency,
                                        Amount: amount,
                                    }),
                                });
                                var ab = await Update.json();
                                if (Update.status == 200) {
                                    handleclose()
                                    submit(updateOrderdata.orderID, "CONFIRMED");
                                    toast.success("Payment Submit Successfully", { autoClose: 2000 });
                                }
                                console.log(ab);
                            } catch (err) {
                                console.log(err);
                                toast.success("Payment failed", { autoClose: 2000 });
                            }
                        }}
                    />
                </PayPalScriptProvider>
            )}
        </div>
    )
}
