import React from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import Home from './Home';
import Menu from './Menu';
 import About from './About';
import Booktable from './Booktable';
import DailyOffer from './DailyOffer'; 
 import { ToastContainer } from 'react-toastify';


function App() {
  return (
    <div className="App">
       <BrowserRouter>
       <ToastContainer/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Menu" element={<Menu />} />
        <Route path="/About" element={<About />} />
        <Route path="/Booktable" element={<Booktable />} />
        <Route path="/Offer" element={<DailyOffer />} />
   
        
      </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
