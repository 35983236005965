import React, { useEffect, useState, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReCAPTCHA from "react-google-recaptcha";

import "./index.css";

import sha512 from "js-sha512";

import SignUp from "./SignUp/SignUp";

export default function Menu() {
  const captchaRef = useRef(null);

  const history = useNavigate();
  const [restaurantData, setRestaurantData] = useState([]);
  const [open, setOpen] = React.useState(false);

  const [email, setEmail] = useState("");
  const [email2, setEmail2] = useState("");

  const [password, setPassword] = useState("");

  const [familyName, setFamilyname] = useState("");
  const [phone, setPhone] = useState("");
  const [noOfGuest, setNoOfGuset] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [mr, setMr] = useState(false);
  const [mrs, setMrs] = useState(false);
  const [salutation, setSaltutation] = useState("");

  const handleClose = () => {
    setOpen(false);
  };
  const handleButtonClick = () => {
    // Focus the input field when the button is clicked
    document.getElementById("myInput").focus();
  };
  const Booktable = async () => {
    const Recaptchatoken = captchaRef?.current?.getValue();
    debugger;
    if (!salutation || !familyName || !noOfGuest || !date || !time || !email || !phone) {
      // If any of the required fields is empty, display an error message or handle it as needed.
      toast.error("Please fill in all required fields.", { autoClose: 5000 });
      debugger
      return; // Exit the function early, don't make the API call.
    }
  
    if (Recaptchatoken|| localStorage.getItem("token")) {
      try {
        var token = localStorage.getItem("token");
        const Update = await fetch(
          "https://restaurantapi.speedbytes.io/api/v1/SubmitTableRequest",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              // Authorization: "Bearer " + token,
            },
            body: JSON.stringify({
              id: 0,
              restaurantId: 47,
              guestId: localStorage.getItem("GuestId")
                ? localStorage.getItem("GuestId")
                : 0,
              salutation: salutation,
              firstName: familyName,
              familyName: familyName,
              noofGuests: noOfGuest,
              dateRequested: date,
              timeSlot: time,
              guestEmail: email,
              guestPhoneNo: phone,
              approved: false,
              tableReserved: "",
              tableRequest: "",
            }),
          }
        );
  
        const ab = await Update.json();
        debugger;
        if (Update.status == 200 || Update.status == 204) {
          toast.success("Table booked successfully ", { autoClose: 5000 });
          captchaRef.current.reset();
  setNoOfGuset(1)
          // setEmail();
          // setFamilyname();
          // setPhone();
          // DateTimeSet();
          // setMr();
          // setMrs();
  
          // setSaltutation();
        } else {
          toast.error("Table booking request failed", { autoClose: 5000 });
        }
      } catch (e) {
        // Handle any errors that occur during the API call.
      }
    }
  };
  
  const GetGuestUser = async (id) => {
    debugger;
    try {
      var token = localStorage.getItem("token");
      const GetguestUserResult = await fetch(
        `https://restaurantapi.speedbytes.io/api/v1/GetGuest?GuestID=${id}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      let result = await GetguestUserResult.json();
      if (
        GetguestUserResult.status == 204 ||
        GetguestUserResult.status == 200
      ) {
        debugger;
        setFamilyname(result.name);
        setPhone(result.mobilenumber);
        setEmail(result.email);
        if (result.salutation === "Mr") {
          setMr(true);
          setMrs(false);
          setSaltutation("Mr");
        } else if (result.salutation === "Mrs") {
          setSaltutation("Mrs");
          setMrs(true);
          setMr(false);
        } else {
          setMrs(false);
          setMr(false);
          setSaltutation("");
        }
      }
    } catch (error) {}
  };
  const logout = async () => {
    debugger;
    var token = localStorage.getItem("token");
    var UserID = localStorage.getItem("UserID");
    try {
      const res = await fetch(
        "https://restaurantapi.speedbytes.io/api/v1/LogOut",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({
            userID: UserID,
          }),
        }
      );
      localStorage.clear();
      history("/");
      const ab = await res.json();
      if (res.status === 200 || res.status === 204) {
        toast.success("Logout Successfull", { autoClose: 5000 });
        localStorage.clear();
        history("/");
      } else {
        toast.error("Logout failed", { autoClose: 5000 });
        localStorage.clear();
        history("/");
      }
    } catch (error) {
      history("/");
      localStorage.clear();
    }
  };
  const BookTablelogin = async () => {
    try {
      const hashPassword = sha512(password);
      const res = await fetch(
        "https://restaurantapi.speedbytes.io/api/v1/Login",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ Email: email2, Password: hashPassword }),
        }
      );

      let loginResult = await res.json();

      if (res.status === 200) {
        localStorage.setItem("token", loginResult.token);
        localStorage.setItem("UserID", loginResult.userID);
        localStorage.setItem("GuestId", loginResult.id);
        GetGuestUser(loginResult.id);
        handleClose();
      }
    } catch (error) {
      toast.error("login failed", { autoClose: 5000 });
    }
  };
const DateTimeSet=()=>{
  setNoOfGuset(1);
  // date
  const today = new Date();

  const formattedDate = today.toISOString().substr(0, 10);

  setDate(formattedDate);
  // Time

  const now = new Date();

  now.setHours(now.getHours() + 1);

  const formattedTime = now.toTimeString().substr(0, 5);

  setTime(formattedTime);
}
  useEffect(() => {
    // Function to fetch restaurant data from the API
    const fetchRestaurantData = async () => {
      try {
        const response = await fetch(
          "https://restaurantapi.speedbytes.io/api/v1/GetRestaurantWebData?restaurantID=47"
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setRestaurantData(data);
        debugger; // Update the state with the API response data
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchRestaurantData();
  }, []);
  useEffect(() => {
    if (localStorage.getItem("token")) {
      let guestid = localStorage.getItem("GuestId");
      GetGuestUser(guestid);
      DateTimeSet()
    } else {
      DateTimeSet()

    }
  }, []);
  return (
    <div>
      <header
        class="header_section"
        style={{ color: "white", backgroundColor: "black" }}
      >
        <div class="container">
          <nav class="navbar navbar-expand-lg custom_nav-container ">
          <NavLink exact to="/" className="nav-link" activeClassName="active">
          <a className="navbar-brand">
            <span>
              {restaurantData?.name}
            </span>
          </a>
        </NavLink>

            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class=""> </span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav mx-auto">
                <li className="nav-item">
                  <NavLink
                    exact
                    to="/"
                    className="nav-link"
                    activeClassName="active"
                  >
                    Home
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/Menu"
                    className="nav-link"
                    activeClassName="active"
                  >
                    Menu
                  </NavLink>
                </li>
                {/* <li className="nav-item">
        <NavLink to="/Offer" className="nav-link" activeClassName="active">
          Daily Offer
        </NavLink>
      </li> */}
                {/* <li className="nav-item">
        <NavLink to="/About" className="nav-link" activeClassName="active">
          About
        </NavLink>
      </li> */}
                <li className="nav-item">
                  <NavLink
                    to="/Booktable"
                    className="nav-link"
                    activeClassName="active"
                  >
                    Book Table
                  </NavLink>
                </li>
              </ul>
              <div class="user_option">
                {localStorage.getItem("token") !== undefined &&
                localStorage.getItem("token") !== null ? (
                  <></>
                ) : (
                  <>
                    {" "}
                    <a
                      href="#"
                      class="user_link"
                      onClick={() => {
                        setOpen(true);
                      }}
                    >
                      <i class="fa fa-user" aria-hidden="true"></i>
                    </a>
                  </>
                )}

                <form class="form-inline"></form>
                <a href="/Menu" className="order_online">
                  Order Now
                </a>

                {localStorage.getItem("token") !== undefined &&
                localStorage.getItem("token") !== null ? (
                  <>
                    {" "}
                    <a
                      className="order_online"
                      style={{ color: "white" }}
                      onClick={() => logout()}
                    >
                      Logout
                    </a>
                  </>
                ) : (
                  <>
                    <a
                      style={{ color: "white" }}
                      className="order_online"
                      data-toggle="modal"
                      data-target=".SignUp"
                    >
                      Register
                    </a>
                  </>
                )}
              </div>
            </div>
          </nav>
        </div>
      </header>
      <section className="book_section layout_padding">
        <div
          className="container"
          style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
        >
          <div className="heading_container">
            <h2>Book A Table</h2>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="img-container">
                <img src="images/pic4.jpg" alt="" className="box-img" />
              </div>
            </div>
            <div className="col-md-6">
              {/* <div className='row' style={{height:"62px",textAlign:"center"}}>
        <div className='col-md-4 col-12'>
    <div>
    <button style={{padding:"16px",backgroundColor:"rgb(255, 190, 51)",color:"white",fontSize: "18px",
    width: "100%",borderRadius:"43px"}}  onClick={handleButtonClick}>Without Login</button> 
     </div>
      </div>
      <div className='col-md-4 col-12'>
     <div>
     <button style={{padding:"16px",backgroundColor:"rgb(255, 190, 51)",color:"white",fontSize: "18px",
    width: "100%" ,borderRadius:"43px"}} onClick={()=>{
 
      setOpen(true)
      setEmail("")
       setPassword("")
    }}> Login </button>
     </div> 
       
      </div>
      <div className='col-md-4 col-12'>
     <div>
     <button style={{padding:"16px",backgroundColor:"rgb(255, 190, 51)",color:"white",fontSize: "18px",
    width: "100%" ,borderRadius:"43px"}}   data-toggle="modal"
              data-target=".SignUp">Register</button>
     </div> 
       
      </div>
        </div> */}

              <div className="form_container">
                <form>
                  <div style={{ textAlign: "left", marginTop: "20px" }}>
                    <label>
                      Mr
                      <input
                        type="checkbox"
                        style={{ marginLeft: "9px" }}
                        checked={mr}
                        onChange={(e) => {
                          setMr(e.target.checked);
                          setMrs(false);
                          setSaltutation("Mr");

                        }}
                      />
                    </label>

                    <label style={{ marginLeft: "9px" }}>
                      Mrs
                      <input
                        type="checkbox"
                        style={{ marginLeft: "9px" }}
                        checked={mrs}
                        onChange={(e) => {
                          setMrs(e.target.checked);
                          setMr(false);
                          setSaltutation("Mrs");

                        }}
                      />
                    </label>
                  </div>

                  <div>
                    <input
                      id="myInput"
                      type="text"
                      className="form-control"
                      placeholder="Family Name"
                      defaultValue={familyName}
                      onChange={(e) => setFamilyname(e.target.value)}
                    />
                  </div>

                  <div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Phone Number"
                      defaultValue={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                  <div>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Your Email"
                      defaultValue={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="No of Guests"
                      value={noOfGuest}
                      onChange={(e) => setNoOfGuset(e.target.value)}
                    />
                  </div>
                  <div>
                    <input
                      type="date"
                      className="form-control"
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                    />
                  </div>
                  <div>
                    <input
                      type="Time"
                      className="form-control"
                      value={time}
                      onChange={(e) => setTime(e.target.value)}
                    />
                  </div>
                  <div className="row">
                  <div className="col-6">
                  {localStorage.getItem("token")?(<></>):(<>
                      {" "}
                      <ReCAPTCHA
                        sitekey={"6LciEj8oAAAAAET7KvPu1ymRa2Pju0AoOApMXdFq"}
                        ref={captchaRef}
                      />
                  </> )}
                   </div>
                    <div className="col-6">
                      <div className="btn_box" style={{ textAlign: "right" }}>
                        <button type="button" onClick={() => Booktable()}>
                          Book Now
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="custom-dialog-title"
          PaperProps={{
            style: {
              minWidth: "700px", // Set the desired width here
              maxWidth: "500px", // Optionally, set the maximum width
            },
          }}
        >
          <DialogTitle
            id="custom-dialog-title"
            style={{
              backgroundColor: "rgb(255, 190, 51)",
              color: "white",
              marginBottom: "10px",
            }}
          >
            Login
          </DialogTitle>
          <DialogContent style={{paddingTop: "10px"}}>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <TextField
                required
                label="Email"
                type="email"
                variant="outlined"
                value={email2}
                onChange={(e) => setEmail2(e.target.value)}
              />
              <TextField
                required
                label="Password"
                type="password"
                variant="outlined"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </DialogContent>
          <DialogActions
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Button
              variant="contained"
              style={{ backgroundColor: "rgb(255, 190, 51)", color: "white" }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              style={{ backgroundColor: "rgb(255, 190, 51)", color: "white" }}
              onClick={() => {
                BookTablelogin();
              }}
              disabled={!email2 || !password}
            >
              Login
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <section className="about_section layout_padding">
        <div className="container  ">
          <div className="row">
            <div className="col-md-6 ">
              <div className="img-box">
                <img
                  src="images/pic10.jpg"
                  alt=""
                  style={{ borderRadius: "100px" }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="detail-box">
                <div className="heading_container">
                  <h2>{restaurantData?.name}</h2>
                </div>
                <p>
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority have suffered alteration in some
                  form, by injected humour, or randomised words which don't look
                  even slightly believable. If you are going to use a passage of
                  Lorem Ipsum, you need to be sure there isn't anything
                  embarrassing hidden in the middle of text. All
                </p>
                {/* <a href="">
              Read More
            </a> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="book_section layout_padding">
    <div className="container">
      <div className="heading_container">
        <h2>
          Book A Table
        </h2>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="form_container">
            <form action="">
              <div>
                <input type="text" className="form-control" placeholder="Your Name" />
              </div>
              <div>
                <input type="text" className="form-control" placeholder="Phone Number" />
              </div>
              <div>
                <input type="email" className="form-control" placeholder="Your Email" />
              </div>
              <div>
                <select className="form-control nice-select wide">
                  <option value="" disabled selected>
                    How many persons?
                  </option>
                  <option value="">
                    2
                  </option>
                  <option value="">
                    3
                  </option>
                  <option value="">
                    4
                  </option>
                  <option value="">
                    5
                  </option>
                </select>
              </div>
              <div>
                <input type="date" className="form-control"/>
              </div>
              <div className="btn_box">
                <button>
                  Book Now
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="col-md-6">
          <div className="map_container ">
            <div id="googleMap"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
  */}

      <section className="client_section layout_padding-bottom">
        <div className="container">
          <div className="heading_container heading_center psudo_white_primary mb_45">
            {/* <h2>
          What Says Our Customers
        </h2> */}
          </div>
          {/* <div className="carousel-wrap row ">
        <div className="owl-carousel client_owl-carousel">
          <div className="item">
            <div className="box">
              <div className="detail-box">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam
                </p>
                <h6>
                  Moana Michell
                </h6>
                <p>
                  magna aliqua
                </p>
              </div>
              <div className="img-box">
                <img src="images/client1.jpg" alt="" className="box-img"/>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="box">
              <div className="detail-box">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam
                </p>
                <h6>
                  Mike Hamell
                </h6>
                <p>
                  magna aliqua
                </p>
              </div>
              <div className="img-box">
                <img src="images/client2.jpg" alt="" className="box-img"/>
              </div>
            </div>
          </div>
        </div>
      </div> */}
        </div>
      </section>

      <footer className="footer_section">
        {/* <div className="container"> */}
        <div className="row">
          <div className="col-md-3 footer-col">
            <div className="footer_contact">
              <h4>Contact Us</h4>
              <div className="contact_link_box">
                <a href="">
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                  <span>
                    {restaurantData?.street} {restaurantData?.postCode}{" "}
                    {restaurantData?.city} - {restaurantData?.country}
                  </span>
                </a>
                <a href="">
                  <i className="fa fa-phone" aria-hidden="true"></i>
                  <span>{restaurantData?.phone}</span>
                </a>
                <a href="">
                  <i className="fa fa-envelope" aria-hidden="true"></i>
                  <span>{restaurantData?.email}</span>
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-6 footer-col">
            <div className="footer_detail">
              <a href="/" className="footer-logo">
                {restaurantData.name}
              </a>
              <p>
                Necessary, making this the first true generator on the Internet.
                It uses a dictionary of over 200 Latin words, combined with
              </p>
              <div className="footer_social">
              <a href={restaurantData?.socialLinks?.[1]?.mediaLink} target="_blank">
                <i className="fa fa-facebook" aria-hidden="true"></i>
              </a>
              <a href={restaurantData?.socialLinks?.[2]?.mediaLink} target="_blank">
                <i className="fa fa-twitter" aria-hidden="true"></i>
              </a>
              <a href={restaurantData?.socialLinks?.[3]?.mediaLink} target="_blank">
                <i className="fa fa-instagram" aria-hidden="true"></i>
              </a>
              <a href={restaurantData?.socialLinks?.[4]?.mediaLink} target="_blank">
                <i className="fa fa-google" aria-hidden="true"></i>
              </a>
                {/* <a href="">
                <i className="fa fa-linkedin" aria-hidden="true"></i>
              </a>
              <a href="">
                <i className="fa fa-instagram" aria-hidden="true"></i>
              </a>
              <a href="">
                <i className="fa fa-pinterest" aria-hidden="true"></i>
              </a> */}
              </div>
            </div>
          </div>
          <div className="" style={{ marginLeft: "65px" }}>
            <h4 style={{ textAlign: "center" }}>Opening Hours</h4>
            {/* <p>
            <span style={{marginRight:"40px"}}>Morning</span> {" "} <span>Evening</span>
          </p>
          {restaurantData?.openingTimes?.map((time,i)=>(
          <p>
          {time.weekDay=="0"?"Sunday":time.weekDay=="1"?"Monday":time.weekDay=="2"?"Tuesday":time.weekDay=="3"?"Wednesday":time.weekDay=="4"?"Thursday":time.weekDay=="5"?"Friday":time.weekDay=="6"?"Saturday":""}  : {time.is24HrsService===true|| (time.morningTime=="-- - --"||time.morningTime=="------")?"Closed":time.morningTime}  :  {time.is24HrsService===true|| (time.eveningTime=="-- - --"||time.eveningTime=="------")?"Closed":time.eveningTime}
          </p>
          ))} */}
            <table className="table" style={{ border: "none" }}>
              <thead style={{ textAlign: "justify" }}>
                <tr>
                  <th style={{ color: "white", border: "none" }}>Day</th>
                  <th style={{ color: "white", border: "none" }}>Morning</th>
                  <th style={{ color: "white", border: "none" }}>Evening</th>
                </tr>
              </thead>
              <tbody style={{ textAlign: "justify" }}>
                {restaurantData?.openingTimes?.map((time, i) => (
                  <tr key={i}>
                    <td
                      style={{
                        border: "none",
                        color: "white",
                        padding: "5px 10px",
                        margin: "0",
                      }}
                    >
                      {time.weekDay === "0"
                        ? "Sunday"
                        : time.weekDay === "1"
                        ? "Monday"
                        : time.weekDay === "2"
                        ? "Tuesday"
                        : time.weekDay === "3"
                        ? "Wednesday"
                        : time.weekDay === "4"
                        ? "Thursday"
                        : time.weekDay === "5"
                        ? "Friday"
                        : time.weekDay === "6"
                        ? "Saturday"
                        : ""}
                    </td>
                    <td
                      style={{
                        border: "none",
                        color: "white",
                        padding: "5px 10px",
                        margin: "0",
                      }}
                    >
                      {time.is24HrsService === true ||
                      time.morningTime === "-- - --" ||
                      time.morningTime === "------"
                        ? "Closed"
                        : time.morningTime}
                    </td>
                    <td
                      style={{
                        border: "none",
                        color: "white",
                        padding: "5px 10px",
                        margin: "0",
                      }}
                    >
                      {time.is24HrsService === true ||
                      time.eveningTime === "-- - --" ||
                      time.eveningTime === "------"
                        ? "Closed"
                        : time.eveningTime}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {/* <div className="footer-info">
        <p>
          &copy; <span id="displayYear"></span> All Rights Reserved By
          <a href="https://html.design/">Free Html Templates</a><br/><br/>
          &copy; <span id="displayYear"></span> Distributed By
          <a href="https://themewagon.com/" target="_blank">ThemeWagon</a>
        </p>
      </div> */}
        {/* </div> */}
      </footer>
      <SignUp />
    </div>
  );
}
